import styled, { css } from 'styled-components';
import type { RuleSet } from 'styled-components';
import { colors, media } from '../../core/styles';
import { Typography } from '../../core/typography/Typography';
import { CloseButton } from '../styles';
import { NextLink } from '../../core/link/NextLink';
import { SiteType } from '~/utilities/graphql/codegen';

const GStarWishlistAction = css`
  top: 6px;
  right: 4px;

  @media ${({ theme }) => media(theme).greaterThan('lg')} {
    top: 12px;
    right: 10px;
  }
`;

const AafWishlistAction = css`
  top: 0;
  right: 0;

  a[data-product-code] & button[data-state='off'] {
    @media ${({ theme }) => media(theme).greaterThan('lg')} {
      opacity: 0;
    }
  }

  a[data-product-code]:hover & button[data-state='off'],
  a[data-product-code]:focus-within:not(:focus:not(:focus-visible)) & button[data-state='off'] {
    opacity: 1;
  }
`;

const WishlistActionStyles: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: GStarWishlistAction,
  [SiteType.Outlet]: GStarWishlistAction,
  [SiteType.Employeeshop]: GStarWishlistAction,
  [SiteType.Aaf]: AafWishlistAction,
};

const WishlistActionWrapper = styled.div`
  z-index: 1;
  position: absolute;
  ${({ theme }) => WishlistActionStyles[theme.siteType]}
`;

const LabelArea = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const GStarInformationArea = css`
  padding-top: ${({ theme }) => theme.padding['2xs']};

  @media ${({ theme }) => media(theme).lessThan('sm')} {
    padding-bottom: ${({ theme }) => theme.padding['2xs']};
  }
`;

const AafInformationArea = css`
  padding: 8px;
`;

const InformationAreaStyles: Record<SiteType, RuleSet<object>> = {
  [SiteType.Gstar]: GStarInformationArea,
  [SiteType.Outlet]: GStarInformationArea,
  [SiteType.Employeeshop]: GStarInformationArea,
  [SiteType.Aaf]: AafInformationArea,
};

const InformationArea = styled.div`
  position: relative;
  z-index: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: inherit;

  ${({ theme }) => InformationAreaStyles[theme.siteType]}
`;

const SigningsArea = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

const QuickLookIcon = styled.div`
  width: 30px;
  height: 30px;
  opacity: 0;
  position: absolute;
  right: 6px;
  top: 0;
`;

const QuickViewWrapper = styled.div`
  margin-left: auto;
  display: none;
  align-items: flex-end;
`;

export const VariantAreaDiv = styled.div`
  display: block;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  padding: 0;
  box-sizing: border-box;
  position: relative;
  background-color: inherit;

  @media ${({ theme }) => media(theme).greaterThan('sm')} {
    position: absolute;
    display: none;
  }
`;

export const baseTextStyle = css`
  font-size: ${({ theme }) => theme.font.size[2]};
  line-height: 1.4;
`;

export const S = {
  InformationArea,
  QuickLookIcon,
  SigningsArea,
  VariantAreaDiv,
  CloseButton,
  LabelArea,
  QuickViewWrapper,
  WishlistActionWrapper,

  PercentArea: styled.div<{ $rightSide?: boolean }>`
    display: flex;
    justify-content: ${({ $rightSide }) => ($rightSide ? 'center' : 'space-between')};
    align-items: ${({ $rightSide }) => ($rightSide ? 'flex-end' : 'center')};
    flex-direction: ${({ $rightSide }) => ($rightSide ? 'column' : 'row')};
    position: relative;
    min-height: 42px;
  `,

  Percent: styled(Typography)<{
    $backgroundColor?: string | null;
    $textColor?: string | null;
    $position?: string | null;
  }>`
    padding: 0 10px;
    font-weight: 700;
    font-size: 12px;
    position: ${({ $position }) => $position || 'initial'};

    &.productSigning-label--long {
      width: 100%;
      text-align: center;
    }

    background-color: ${({ $backgroundColor }) => $backgroundColor ?? colors.PURE_RED};
    color: ${({ $textColor }) => $textColor ?? colors.WHITE};

    &.productSigning--v2--position1 {
      top: 10px;

      &.fiber-ranking-visible {
        top: 70px;
      }
    }

    &.productSigning--v2--position2 {
      top: 50px;
    }

    &.productSigning--v2--position3,
    &.productSigning--v2--position4 {
      top: calc(50% - 20px);
    }

    &.productSigning--v2--position3,
    &.productSigning--v2--position1 {
      left: 0;
    }

    &.productSigning--v2--position2,
    &.productSigning--v2--position4 {
      right: 0;
    }
  `,

  Label: styled(Typography)<{ $backgroundColor?: string | null }>`
    background-color: ${({ $backgroundColor }) => $backgroundColor || colors.SIGNING_WHITE};
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.font.size[2]};
    font-weight: ${({ theme }) => theme.overrides.font?.weight.medium};
  `,

  Tile: styled.div`
    height: 100%;
  `,

  /* set tile as "hovered" when user uses only the keyboard */
  /* without ":focus-within:not(:focus:not(:focus-visible))" the state will also be set when user clicks buttons */
  TileLink: styled(NextLink)`
    display: flex;
    flex-direction: column;
    height: 100%;

    ${InformationArea}, ${SigningsArea}, ${LabelArea} {
      @media (${({ theme }) => media(theme).noMotionPreference}) {
        transition: transform 0.1s ease-in-out;
      }
    }

    ${QuickLookIcon} {
      @media (${({ theme }) => media(theme).noMotionPreference}) {
        transition: opacity 0.1s ease-in-out;
      }
    }

    &[data-hover-variant-area='true']:hover,
    &[data-hover-variant-area='true']:focus-within:not(:focus:not(:focus-visible)) {
      @media ${({ theme }) => media(theme).greaterThan('sm')} {
        ${InformationArea}, ${SigningsArea}, ${LabelArea} {
          transform: translateY(-32px);
        }
      }
    }

    &:hover,
    &:focus-within:not(:focus:not(:focus-visible)) {
      ${VariantAreaDiv} {
        display: block;
      }

      ${QuickLookIcon} {
        opacity: 1;
      }

      ${CloseButton} {
        visibility: visible !important;
      }

      ${QuickViewWrapper} {
        @media ${({ theme }) => media(theme).greaterThan('sm')} {
          display: flex;
        }
      }
    }
  `,

  ProductWrapper: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  `,

  FiberSigningsWrapper: styled.div`
    position: absolute;
    left: 12px;
    top: 12px;
    z-index: 1;
  `,

  RelativeElement: styled.div`
    background-color: inherit;
    position: relative;
    flex: 1;
  `,

  OneLineText: styled(Typography)`
    ${baseTextStyle};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `,

  Price: styled(Typography)`
    ${baseTextStyle};
    font-weight: ${({ theme }) => theme.overrides.font?.weight.medium};

    &.price-textMedium {
      color: ${({ theme }) => theme.colors.content.secondary};
    }

    &.price-textBold {
      font-weight: ${({ theme }) => theme.overrides.font?.weight.bold};
    }
  `,

  PriceArea: styled.div`
    display: flex;
    gap: 6px;
    justify-content: center;
    white-space: nowrap;
  `,

  ProductData: styled.div`
    background-color: ${colors.WHITE};
    display: flex;
    flex-direction: column;
    flex: 1;
  `,
};
